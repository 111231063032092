import { useState, useEffect, useContext, useCallback } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Table,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { Device } from "../../interfaces/Device";
import "../VicuManagement/VicuManagement.css";
import { ReactNode } from "react";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import DeviceService from "../../services/DeviceService";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../ReRoute/ServerProblem";
import QRCode from "react-qr-code";
import { getQRCode } from "../../utils/functions/QRData";
import { UserContext } from "../../context/UserContext";
import DeviceRow from "./DeviceRow";
import Legend from "./Legend";
import { useTranslation } from "react-i18next";
import { getStatusDot, formatTimestamp } from "./DeviceRow";
import { DeviceMgmtDialog } from "../DeviceManagement/DeviceMgmtDialog";

interface Props {
  devicelist: Device[];
  children?: ReactNode;
  organization_id: string;
  filterValue: number;
}

export const HEARTBEAT_GREEN = 10; // 10 seconds
export const HEARTBEAT_YELLOW = 600; // 10 minutes
export const HEARTBEAT_GREY = 86400; // 24 hours
export const HEARTBEAT_GREEN_COLOR = "#00C000";
export const HEARTBEAT_YELLOW_COLOR = "orange";
export const HEARTBEAT_GREY_COLOR = "grey";
export const HEARTBEAT_NODATA_COLOR = "grey";

export const MissionCentral = ({
  organization_id,
  devicelist,
  children,
  filterValue,
}: Props) => {
  const theme = useTheme();
  const [qrData, setQrData] = useState("");
  const userCtx = useContext(UserContext);
  const { t } = useTranslation();
  const [cookies] = useCookies(["access_token"]);

  // **Create a local state for the device list**
  const [deviceList, setDeviceList] = useState<Device[]>(devicelist);

  const [activityStatus, setActivityStatus] = useState<{ [key: string]: string }>({});
  const [activityLevels, setActivityLevels] = useState<{ [key: string]: number }>({});
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [showAdminButtons, setShowAdminButtons] = useState(true);
  const [openEraseDialog, setOpenEraseDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);

  const {
    data: alarmProfiles,
    loading: almLoading,
    error: almError,
  } = useFetch({
    url: DeviceService.getTypes(),
    access_token: cookies.access_token,
  });

  // **Update local device list when props change**
  useEffect(() => {
    setDeviceList(devicelist);
  }, [devicelist]);

  // Helper function to determine activity status level
  const calculateActivityLevel = (timestamp: string | undefined): number => {
    const currentTime = new Date().getTime();
    if (!timestamp) return 4; // No data, assign the lowest priority
    const activityTime = new Date(timestamp).getTime();
    const timeDiffSeconds = (currentTime - activityTime) / 1000;

    if (timeDiffSeconds <= HEARTBEAT_GREEN) {
      return 1; // GREEN
    } else if (timeDiffSeconds <= HEARTBEAT_YELLOW) {
      return 2; // YELLOW
    } else if (timeDiffSeconds <= HEARTBEAT_GREY) {
      return 3; // GREY
    } else {
      return 4; // NODATA
    }
  };

  useEffect(() => {
    if (deviceList.length === 0) {
      setActivityStatus({});
      setActivityLevels({});
    } else {
      DeviceService.getActivityStatus(cookies.access_token, organization_id, deviceList).then(
        (response: any) => {
          //console.log("response: ", response);
          const newActivityStatus = response.activity || {};
          setActivityStatus(newActivityStatus);

          // Initialize activity levels with the fetched activity status
          const newActivityLevels: { [key: string]: number } = {};
          deviceList.forEach((device) => {
            const deviceId = device.device_id;
            const timestamp = newActivityStatus[deviceId];
            newActivityLevels[deviceId] = calculateActivityLevel(timestamp);
          });
          setActivityLevels(newActivityLevels);
        }
      );
    }
  }, [deviceList, cookies.access_token, organization_id]);

  useEffect(() => {
    setSelectedDevice(null);
  }, [deviceList]);

  /*
   * Update activity status every second
   */
  useEffect(() => {
    const interval = setInterval(() => {
      setActivityStatus((prevStatus) => {
        const updatedStatus = { ...prevStatus };
        const updatedLevels = { ...activityLevels };

        Object.keys(updatedStatus).forEach((deviceId) => {
          // Update activity level based on the time difference
          updatedLevels[deviceId] = calculateActivityLevel(updatedStatus[deviceId]);

          // Update to trigger re-render if necessary
          if (updatedStatus[deviceId]) {
            updatedStatus[deviceId] = new Date(updatedStatus[deviceId]).toISOString();
          }
        });
        setActivityLevels(updatedLevels);
        return updatedStatus;
      });
    }, 1000); // Check every second

    return () => clearInterval(interval);
  }, [activityLevels]);

  useEffect(() => {
    setShowAdminButtons(userCtx?.user?.role?.roletype === "orgadmin");
  }, [userCtx?.user?.role?.roletype]);

  const handleRowClick = (device: Device) => {
    setSelectedDevice(device);
    setQrData(userCtx?.user ? JSON.stringify(getQRCode(userCtx?.user, device)) : "");
  };

  const updateActivityStatus = useCallback((device_id: string, timestamp: string) => {
    setActivityStatus((prevStatus) => ({
      ...prevStatus,
      [device_id]: timestamp,
    }));

    // Update the activity level as well
    setActivityLevels((prevLevels) => ({
      ...prevLevels,
      [device_id]: calculateActivityLevel(timestamp),
    }));
  }, []);

  if (!alarmProfiles || almLoading) {
    return <Loading />;
  }
  if (almError) {
    return <ServerProblem />;
  }
  const isDeviceVisible = (device: Device) => {
    const currentTime = new Date().getTime();
    const timestamp = activityStatus[device.device_id];
    if (!timestamp) return filterValue === Infinity;
    const activityTime = new Date(timestamp).getTime();
    const timeDiffSeconds = (currentTime - activityTime) / 1000;
    return timeDiffSeconds <= filterValue;
  };

  // **Use deviceList state instead of devicelist prop**
  const sortedDevicelist = deviceList.slice().sort((a, b) => {
    const aLevel = activityLevels[a.device_id] || 4;
    const bLevel = activityLevels[b.device_id] || 4;

    if (aLevel !== bLevel) {
      return aLevel - bLevel; // Sort by activity status level
    } else {
      // If same status level, sort by device_id
      return a.device_id.localeCompare(b.device_id);
    }
  });

  const handleEraseButton = () => {
    setOpenEraseDialog(true);
  };

  const handleEditDevice = () => {
    setShowEditDialog(true);
  };

  const handleEraseHotstorage = () => {
    if (!selectedDevice) return;

    // Send msg to back-end to erase hotstorage data for this device.
    const data = { device_id: selectedDevice.device_id };
    DeviceService.eraseHotstorage(data, cookies.access_token).then((res: any) => {
      if (res.status === 200) {
        // Remove the device from activityStatus
        setActivityStatus((prevStatus) => {
          const newStatus = { ...prevStatus };
          delete newStatus[selectedDevice.device_id];
          return newStatus;
        });

        // Remove the device from activityLevels
        setActivityLevels((prevLevels) => {
          const newLevels = { ...prevLevels };
          delete newLevels[selectedDevice.device_id];
          return newLevels;
        });
      }
    });
    setOpenEraseDialog(false);
  };

  const handleCloseErase = () => {
    setOpenEraseDialog(false);
  };

  // **Modify editDevice function to update the device list**
  const editDevice = (updatedDevice: Device, action?: string) => {
    if (action === "edit") {
      console.log("Updated device: ", updatedDevice);

      // Update the device in the local deviceList state
      setDeviceList((prevDeviceList) =>
        prevDeviceList.map((device) =>
          device.device_id === updatedDevice.device_id ? updatedDevice : device
        )
      );

      // Update the selectedDevice to reflect changes in the details panel
      setSelectedDevice(updatedDevice);

      // Optionally, refresh activity status for the updated device
      DeviceService.getActivityStatus(cookies.access_token, organization_id, [updatedDevice]).then(
        (response: any) => {
          const newActivityStatus = response.activity || {};
          const deviceId = updatedDevice.device_id;
          const timestamp = newActivityStatus[deviceId];

          setActivityStatus((prevStatus) => ({
            ...prevStatus,
            [deviceId]: timestamp,
          }));

          setActivityLevels((prevLevels) => ({
            ...prevLevels,
            [deviceId]: calculateActivityLevel(timestamp),
          }));
        }
      );
    }
    setShowEditDialog(false);
  };

  //console.log ("selectedDevice is ", selectedDevice)
  return (
    <Box sx={{ flexGrow: 1 }}>
      {children}
      <Grid container direction="row" spacing={2}>
        <Grid item xs={8}>
          {deviceList?.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>{t("device_id")}</TableCell>
                      <TableCell>{t("model")}</TableCell>
                      <TableCell>{t("nickname")}</TableCell>
                      <TableCell>{t("status")}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedDevicelist.map((device, index) => (
                      <DeviceRow
                        key={device.device_id}
                        isVisible={isDeviceVisible(device)}
                        index={index}
                        device={device}
                        selectedDevice={selectedDevice}
                        activityStatus={activityStatus}
                        handleRowClick={handleRowClick}
                        updateActivityStatus={updateActivityStatus}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Legend />
            </>
          ) : (
            <Typography>{t("no_devices_to_show")}</Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          {selectedDevice && (
            <Box sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 4 }}>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    sx={{ color: theme.palette.primary.main, fontSize: "1.25rem" }}
                  >
                    {t("alias")}:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    sx={{ color: theme.palette.primary.main, fontSize: "1.25rem" }}
                  >
                    {selectedDevice.nickname}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{t("device_id")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedDevice.device_id}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{t("type")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedDevice.device_type}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{t("model")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedDevice.device_model}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{t("serialnumber")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedDevice.device_serialnumber}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{t("location")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedDevice.location}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{t("last_activity")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {getStatusDot(activityStatus[selectedDevice.device_id])}{" "}
                    {formatTimestamp(activityStatus[selectedDevice.device_id])}
                  </Typography>
                </Grid>

                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={6}>
                        <Box sx={{ textAlign: "center" }}>
                        <Box
                            sx={{
                            display: "inline-block",
                            backgroundColor: "white",
                            padding: 1,
                            }}
                        >
                            <QRCode value={qrData} size={128} bgColor="white" fgColor="black" />
                        </Box>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={6}
                    >
                        {showAdminButtons && (
                        <Stack spacing={2} alignItems="flex-end">
                            <Button onClick={handleEditDevice} variant="outlined">
                            {t("edit_device")}
                            </Button>
                            <Button onClick={handleEraseButton} variant="outlined" color="error">
                            {t("clear_device_data")}
                            </Button>
                        </Stack>
                        )}
                    </Grid>
                    </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>

      {/* -------------------------------- DIALOGS -------------------------------- */}
      <Dialog open={openEraseDialog}>
        <DialogTitle> {t("clear_device_data")} </DialogTitle>
        <DialogContent>
          <Typography ml={1}>{t("confirm_erase_device_data")}</Typography>
          <Typography ml={1}>{t("this_will_erase_all_history_data")}</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseErase}> {t("no")}</Button>
          <Button onClick={handleEraseHotstorage} variant="contained" color="error" > {t("yes")}</Button>
        </DialogActions>
      </Dialog>

      {selectedDevice && showEditDialog && (
        <DeviceMgmtDialog
          action={"edit"}
          openState={showEditDialog}
          setOpenState={setShowEditDialog}
          device={selectedDevice}
          alarmProp={alarmProfiles}
          deviceAction={editDevice}
        />
      )}
    </Box>
  );
};
