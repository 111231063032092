import { User } from "../../interfaces/user/User"
import { Device } from "../../interfaces/Device";
//import * as CONSTANTS from "../constants/constants";


export const getQRCode = (user: User, device: Device) => {
    if (!user?.qr_data) {
        return {}
    }
    const qr_data = JSON.parse(user.qr_data);
    qr_data["device_id"] = device.device_id;
    qr_data["device_model"] = device.device_model;
    qr_data["device_nickname"] = device.nickname;
    qr_data["sno"] = device.device_serialnumber;
    qr_data["org"] = user.organization;
    qr_data["demo_mode"] = device?.demo_mode || false;
    //qr_data["api"] = CONSTANTS.HOST;
    delete qr_data["stream"];
    delete qr_data["api"]   
    //console.log ("qr_data is ", qr_data)
    //console.log ("device:", device)
    return qr_data
}
