import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Grid,
    Button
} from '@mui/material';
import GenericDropdown from "../pages/DeviceManagement/GenericDropdown";
import { useTranslation } from 'react-i18next';

interface InterventionDialogProps {
    open: boolean;
    onClose: () => void;
    dropdownOptions: { value: string; label: string }[];
    selectedIntervention: string | null;
    onSelectIntervention: (value: string) => void;
    onSelectLive: () => void;
}

const InterventionDialog: React.FC<InterventionDialogProps> = ({
    open,
    onClose,
    dropdownOptions,
    selectedIntervention,
    onSelectIntervention,
    onSelectLive,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>{t("select_intervention")}</DialogTitle>
            <DialogContent
                style={{ minWidth: "400px" }} // Adjust the minWidth to make the dropdown wider
            >
                <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <GenericDropdown
                                options={dropdownOptions}
                                selectedValue={selectedIntervention || ''}
                                onChange={onSelectIntervention}
                                title={t("interventions")}
                                sx={{ width: '100%', minWidth: 'auto', maxWidth: '100%' }} // Allow the dropdown to expand based on content
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={onSelectLive}>
                                {t("live")}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t("close")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default InterventionDialog;
