export const patientVitalsColor = (theme: string = "default") => {

    if (theme === "touch7") {
        return {
            HR: "#FF5733",
            SpO2: "#6283e8",
            Perf: "#6283e8",
            IBP1: "#FF33FF",
            IBP2: "#FF66FF",
            NIBP: "#7880a3",
            Temp: "#293393",
            Temp1: "#293393",
            Temp2: "#FFD700",
            temp: "#FFD700",
            EtCO2: "#FFA500",
            Resp: "#32CD32",
            resp: "#32CD32",
            Posture: "#FFFFFF",
        };
    }

    
    return {
        HR: "#006000",
        SpO2: "#6283e8",
        Perf: "#6283e8",
        IBP1: "#820000",
        IBP2: "#820000",
        NIBP: "#7880a3",
        Temp1: "#293393",
        Temp2: "#293393",
        Temp: "#293393",
        temp: "#293393",
        EtCO2: "#ff00ff",
        Resp: "#ff8501",
        resp: "#ff8501",
        Posture: "#FFFFFF",
    };
};

// const colors = {
//     infiniwellBlue: "#416BA9",
//     infiniwellGreen: "#8DC63F",
//     infiniwellRed: "#EF4136",
//     infiniwellGrey: "#D1D3D4",
//     infiniwellYellow: "#FFDE17",
//     infiniwellOrange: "#F26522",
//     infiniwellBg: "#92B6D5",
//     infiniwellText: "#203E4A",

//     // text-white-high: "rgba(255, 255, 255, 1)",
//     // text-white-medium: "rgba(255, 255, 255, 0.6)",
//     // text-white-disabled: "rgba(255, 255, 255, 0.38)",
//     // text-black-high: "rgba(0, 0, 0, .87)",
//     // text-black-medium: "rgba(0, 0, 0, 0.6)",
//     // text-black-disabled: "rgba(0, 0, 0, 0.38)",

//     // surface: #F9F9F9,
//     // surface-light: #FFFFFF,
//     // surface-dark: #E8E8E8,

//     // primary: #424242,
//     // primary-900: #212121,
//     // primary-800: #424242,
//     // primary-600: #757575,

//     // secondary: #3FA8F3,
//     // secondary-900: #004BA1,

//     // text-HR: #33FF55,
//     // text-SPO2: yellow,
//     // text-NIBP: #FE259F,
//     // text-IBP1: #FF33F9,
//     // text-IBP2: #FE259F,
//     // text-TEMP: #FFFFFF,
//     // text-RESP: #75FBFD,
//     // text-monitor-alarm: #FF0000,

//     // news-red: #F29983,
//     // news-orange: #FCCD8C,
//     // news-yellow: #FDF2B0,
// };
