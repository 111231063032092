import { Loading } from "../components/Loading";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import useFetch from "../hooks/useFetch";
import { ServerProblem } from "./ReRoute/ServerProblem";
import DeviceService from "../services/DeviceService";
import { CareSummaryInfo } from "../components/chart/CareSummaryInfo";
import { MAX_REPORT_DURATION_SEC } from "../components/chart/CareSummaryInfo";
import { useEffect, useState } from "react";
import axios from "axios"; // Import axios for making API calls

/*
 * The CareSummary component loads one-time data and passes it down to the CareSummaryInfo component which 
 * implements the page.
 */
export const CareSummary = () => {
  //const params = useParams();
  const [cookies] = useCookies(["access_token"]);
  const { deviceId } = useParams<{ deviceId: string }>();
  const [parmHist, setParmHist] = useState();
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0); // State to trigger re-fetch
  const [interventions, setInterventions] = useState([]); // Store fetched interventions
  const [interventionsLoading, setInterventionsLoading] = useState(true); // Loading state for interventions

  // Get details on the device
  const { data: device, loading, error } = useFetch({
    url: DeviceService.getDevice(),
    access_token: cookies.access_token,
    id: deviceId,
  });

  // Fetch interventions when component loads and when refreshKey changes
  useEffect(() => {
    let isMounted = true; // Flag to check if the component is mounted

    const fetchInterventions = async () => {
      setInterventionsLoading(true); // Set loading state
      try {
        // Use the getInterventions method to get the URL
        const url = DeviceService.getInterventions(deviceId);

        // Perform the request using axios
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${cookies.access_token}`, // Pass the token in the headers
          },
        });

        // Update the interventions state with the response data only if the component is still mounted
        if (isMounted) {
          setInterventions(response.data);
          //console.log ("Interventions:", response.data);
          let inters = response?.data;
          //console.log("Interventions before sorting:", inters);
      
          // Sort interventions based on min_time
          inters = inters.sort((a: any, b: any) => new Date(a.min_time).getTime() - new Date(b.min_time).getTime());
      
          //console.log("Interventions after sorting:", inters);
      
          setInterventions(inters);
          if (inters.length > 0) {
            //setLiveIntervention(inters[inters.length - 1].intervention_id as string);
          }
  
        }
      } catch (error) {
        console.error("Error fetching interventions:", error);
      } finally {
        if (isMounted) {
          setInterventionsLoading(false); // Set loading state to false
        }
      }
    };

    fetchInterventions(); // Trigger the fetch

    return () => {
      isMounted = false; // Cleanup function to prevent state updates after unmount
    };
  }, [deviceId, cookies.access_token, refreshKey]); // Refresh interventions when refreshKey changes

  //useEffect(() => {
  //  console.log("CareSummary - interventions:", interventions);
  //}, [interventions]);

  // Retrieve historical vital sign values
  const now = new Date();
  const start = now;
  start.setSeconds(start.getSeconds() - MAX_REPORT_DURATION_SEC);

  useEffect(() => {
    let isMounted = true; // Flag to check if the component is mounted

    const d = {
      device_id: deviceId,
      duration: MAX_REPORT_DURATION_SEC,
    };

    DeviceService.getTrendData(d, cookies.access_token).then((res: any) => {
      if (isMounted) {
        setParmHist(res.data);
        setLoadingHistory(false);
      }
    });

    return () => {
      isMounted = false; // Cleanup function to prevent state updates after unmount
    };
  }, [deviceId, cookies.access_token, refreshKey]); // Added refreshKey as dependency to trigger re-fetch

  // One-time on component load: retrieve alarm profiles from back-end
  const { data: alarmProp, loading: almLoading } = useFetch({
    url: DeviceService.getTypes(),
    access_token: cookies.access_token,
  });

  if (loading || almLoading || loadingHistory || interventionsLoading || device === null) return <Loading />;
  if (!parmHist) return <Loading />;
  if (error) { return <ServerProblem />; }

  const almProfileDef = JSON.parse(device.alarm_profile);

  // Function to trigger a re-fetch by updating the refreshKey
  const updateInterventions = () => {
    console.log("updateInterventions: refreshing interventions: ", refreshKey);
    setRefreshKey((prev) => prev + 1); // Increment refreshKey to trigger re-render and re-fetch interventions
  };

  //console.log("CareSummary renders - refreshKey:", refreshKey, interventions.length, interventions);
  return (
    <CareSummaryInfo
      device={device}
      initialAlmProfileDef={almProfileDef}
      parmHistory={parmHist}
      alarmProp={alarmProp}
      interventions={interventions}
      updateInterventions={updateInterventions} // Pass the updateInterventions to the child
      refreshKey={refreshKey} // Pass refreshKey to ensure re-render when it changes
    />
  );
};
