import { Grid, GridSize } from "@mui/material";
import { useEffect, useState, memo, useMemo, ReactNode } from "react";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import useVitalsSocket from "../../hooks/useVitalsSocket";
import { Device } from "../../interfaces/Device";
import DeviceService from "../../services/DeviceService";
import { ParameterDisplay } from "./ParameterDisplay";
import { CompactMonitor } from "./CompactMonitor";
import { SelectParameters } from "./SelectParameters";
import { TopPartMonitorNEWS } from "./TopPartMonitorNEWS";
import { Intervention } from "../../components/chart/interfaces";
import { ParameterBlock, ParameterValue } from "./ParameterDisplay";

interface Props {
  device: Device;
  compact?: boolean;
  fullWidth?: boolean;
  setDevices?: (devices: Device[]) => void;
  width?: GridSize;
  selectParams?: ReactNode;
  availableParams: string[];
  type?: string;
  useLink?: boolean;
  dropdownComponent?: ReactNode;
  selectedIntervention?: string | null;
  setSelectedIntervention?: (value: string) => void;
  showInterventionSelector?: boolean;
  showConnectStatus?: boolean;
  interventions?: Intervention[];
  liveIntervention?: string;
  isVisible: boolean;
	updateActivityStatus?: (device_id: string, timestamp: string) => void;
  showLive?: boolean;
  setDimmedState?: (dimmed: boolean) => void; // Add dimming state control
}

const ALL_PARAMS = ["HR", "SpO2", "IBP1", "NIBP", "Resp", "Temp"]; // , "IBP2",


const DeviceMonitor = ({
  device,
  compact = false,
  fullWidth = false,
  useLink = true,
  setDevices,
  width = 4,
  availableParams,
  type = "devicemonitor",
  interventions,
  selectedIntervention,
  setSelectedIntervention,
  showInterventionSelector = false,
  showConnectStatus = true,
  liveIntervention,
  isVisible,
  updateActivityStatus,
  showLive = true,
  setDimmedState
}: Props) => {
  const { vitals, news, patientInfo, connectStatus, timestamp } = useVitalsSocket({
    device,
    setDevices,
  });

  const savedParams = useMemo(() => {
    const storedParams = type === "mapmonitor"
      ? availableParams
      : JSON.parse(localStorage.getItem(`${type}-${device.device_id}`) || '[]');
  
    // If no saved parameters are found, default to ALL_PARAMS
    return storedParams.length > 0 ? storedParams : ALL_PARAMS;
  }, [availableParams, type, device.device_id]);
  

  const [selectableParameters, setSelectableParameters] = useState<string[]>([]);
  const [selectedParameters, setSelectedParameters] = useState<string[]>(
    savedParams || ALL_PARAMS
  );

  const [cookies] = useCookies(["access_token"]);

  //console.log ("savedParms:", savedParams)
  const { data } = useFetch({
    url: DeviceService.getDevice(),
    access_token: cookies.access_token,
    id: device?.device_id,
  });

  useEffect(() => {
    if (data && device.device_id !== "None") {
      let parameters = JSON.parse(data.device_def).parameters;
      let params: any[] = [];
      Object.keys(parameters).forEach((key) => {
        let temp = key;
        if (temp.includes("_")) temp = temp.split("_")[0];
        else if (temp === "SPO2") temp = "SpO2";
        else if (temp === "RR") temp = "Resp";
        else if (temp === "Temp1") temp = "Temp";

        if (!params.includes(temp) && ALL_PARAMS.includes(temp)) {
          params.push(temp);
        }
      });
      setSelectableParameters(params);

      if (!savedParams)
        setSelectedParameters(() => {
          if (params.length > 6)
            return params.filter((_param, index) => index < 6);
          else return params;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateActivityStatus) {
      updateActivityStatus(device.device_id, timestamp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device.device_id, timestamp]);


  // Hook to translate vitals into ParameterBlocks
  const useTranslateVitals = (vitals: any) => {
    const [parameterBlocks, setParameterBlocks] = useState<ParameterBlock[]>([]);
  
    const isValidValue = (value: any) =>
      value !== undefined && value !== "" && value !== "-";
  
    useEffect(() => {
      if (!vitals) return;
  
      const transformedVitals: ParameterBlock[] = Object.keys(vitals).reduce(
        (acc: ParameterBlock[], key) => {
          const vital = vitals[key];
          if (!vital) return acc; // Skip undefined parameters
  
          let subparameters: Record<string, ParameterValue> = {};
  
          switch (key) {
            case "HR":
              acc.push({
                param_name: "HR",
                active_alarm: vital.active_alarm || false,
                value: {
                  value: isValidValue(vital.value) ? vital.value : "-",
                  unit: vital.unit || "",
                  text: vital.text || "",
                },
              });
              break;
  
              case "SpO2":
                // Initialize subparameters with sp
                subparameters = {
                  sp: {
                    value: isValidValue(vital.sp?.value) ? vital.sp?.value : "-",
                    unit: vital.sp?.unit || "",
                    text: vital.sp?.text || "",
                  },
                };
              
                // Conditionally add perf if it's valid and not undefined
                if (vital.perf && isValidValue(vital.perf?.value)) {
                  subparameters.perf = {
                    value: vital.perf.value,
                    unit: vital.perf.unit || "",
                    text: vital.perf.text || "",
                  };
                }
              
                acc.push({
                  param_name: "SpO2",
                  active_alarm: vital.active_alarm || false,
                  subparameters,
                });
                break;
                
            case "IBP1":
            case "IBP2":
            case "NIBP":
              subparameters = {
                systole: {
                  value: isValidValue(vital.systole?.value)
                    ? vital.systole?.value
                    : "-",
                  unit: vital.systole?.unit || "",
                  text: vital.systole?.text || "",
                },
                diastole: {
                  value: isValidValue(vital.diastole?.value)
                    ? vital.diastole?.value
                    : "-",
                  unit: vital.diastole?.unit || "",
                  text: vital.diastole?.text || "",
                },
                map: {
                  value: isValidValue(vital.map?.value) ? vital.map?.value : "-",
                  unit: vital.map?.unit || "",
                  text: vital.map?.text || "",
                },
              };
              acc.push({
                param_name: key,
                active_alarm: vital.active_alarm || false,
                subparameters,
              });
              break;
  
              case "Temp":              
                // Initialize subparameters as an empty object
                subparameters = {};
              
                // Only add t1 if it's valid and not undefined
                if (vital.t1) {
                  subparameters.t1 = {
                    value: isValidValue(vital.t1?.value) ? vital.t1?.value : "-",
                    unit: vital.t1?.unit || "",
                    text: vital.t1?.text || "",
                  };
                }
              
                // Only add t2 if it's valid and not undefined
                if (vital.t2) {
                  subparameters.t2 = {
                    value: isValidValue(vital.t2?.value) ? vital.t2?.value : "-",
                    unit: vital.t2?.unit || "",
                    text: vital.t2?.text || "",
                  };
                }
              
                // Add the Temp parameter to the accumulator only if subparameters are not empty
                if (Object.keys(subparameters).length > 0) {
                  acc.push({
                    param_name: "Temp",
                    active_alarm: vital.active_alarm || false,
                    subparameters,
                  });
                }
              
                break;

                // Handle Resp and EtCO2, combining them if both exist
            case "Resp":
            case "EtCO2":
              // Find or create a new 'Resp' block
              let existingResp = acc.find((item) => item.param_name === "Resp");
  
              if (!existingResp) {
                existingResp = {
                  param_name: "Resp",
                  active_alarm: false,
                  subparameters: {}, // Initialize subparameters as an empty object
                };
                acc.push(existingResp);
              }
  
              // Ensure subparameters is always defined
              existingResp.subparameters = existingResp.subparameters || {};
  
              // Handle 'Resp' parameters
              if (key === "Resp") {
                existingResp.subparameters.rr = {
                  value: isValidValue(vital.rr?.value) ? vital.rr?.value : "-",
                  unit: vital.rr?.unit || "",
                  text: vital.rr?.text || "",
                };
                existingResp.active_alarm =
                  existingResp.active_alarm || vital.active_alarm || false;
              }
  
              // Handle 'EtCO2' parameters
              if (key === "EtCO2") {
                existingResp.subparameters.co2 = {
                  value: isValidValue(vital.co2?.value) ? vital.co2?.value : "-",
                  unit: vital.co2?.unit || "",
                  text: vital.co2?.text || "",
                };
                existingResp.active_alarm =
                  existingResp.active_alarm || vital.active_alarm || false;
              }
              break;
  
            default:
              console.log("Unknown parameter: ", key, vital);
              break;
          }
  
          return acc;
        },
        []
      );
  
      setParameterBlocks(transformedVitals);
    }, [vitals]);
  
    return parameterBlocks;
  };
  



  // Translate vitals into ParameterBlocks
  const parameterBlocks = useTranslateVitals(vitals);
  // Filter and order parameters based on the predefined order and selected parameters
  const orderedAndFilteredBlocks = useMemo(() => {
    return parameterBlocks
      .filter((block) => selectedParameters.includes(block.param_name))
      .sort((a, b) => ALL_PARAMS.indexOf(a.param_name) - ALL_PARAMS.indexOf(b.param_name));
  }, [parameterBlocks, selectedParameters]);

  if (!isVisible) {
    return <></>
  }
  if (compact) {
    return (
      <Grid container alignContent="baseline" padding="3px">
        <Grid item xs={12}>
          <TopPartMonitorNEWS
            device={device}
            connectStatus={connectStatus}
            showConnectStatus={showConnectStatus}
            patientInfo={patientInfo}
            news={news}
            selectedIntervention={selectedIntervention}
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          sx={{
            backgroundColor: "black",
            zIndex: 1, // Adjust as necessary to bring CompactMonitor behind TopPartMonitorNEWS
          }}
        >
          <CompactMonitor parameters={orderedAndFilteredBlocks} connectStatus={connectStatus} />
        </Grid>
      </Grid>
    );
  }

  

  return (
    <Grid item container sm={12} md={!fullWidth ? 4 : 12} alignContent="baseline" padding="3px">
      <TopPartMonitorNEWS
        device={device}
        news={news}
        patientInfo={patientInfo}
        connectStatus={connectStatus}
        useLink={useLink}
        showInterventionSelector={showInterventionSelector}
        selectedIntervention={selectedIntervention}
        setSelectedIntervention={setSelectedIntervention}
        interventions={interventions}
        selectParams={
          <SelectParameters
            deviceId={device.device_id}
            selectableParameters={selectableParameters}
            selectedParameters={selectedParameters}
            setSelectedParameters={setSelectedParameters}
            maxParameters={6}
            type={type}
          />
        }
        showDeviceName={false}
        setDimmedState={setDimmedState}
      />

      {/* if (!selectedParameters.includes(block.param_name)) return null;*/}
      {showLive && orderedAndFilteredBlocks.map((block) => (
        <ParameterDisplay
          key={block.param_name}
          parameter={block}
          connectStatus={connectStatus}
        />
      ))}
    </Grid>
  );
};

export default memo(DeviceMonitor);
