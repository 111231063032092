import {
    Grid,
    Typography,
    useTheme,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,

} from "@mui/material";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { IngestionServer } from "../../interfaces/IngestionServer"

import "../VicuManagement/VicuManagement.css"
import IngestionServerService from "../../services/IngestionServerService";

// These need to be matched with values in server-side ServerTypeChoices
const NATIVE_KAFKA    = "Native Kafka";
const AZURE_EVENT_HUB = "Azure EventHub";

// Custom styling for the switch
export const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
      // Default (unchecked) state
      color: 'blue', // Replace with your preferred color for the thumb
      '& + .MuiSwitch-track': {
        backgroundColor: 'grey', // Replace with your preferred color for the track
      },
      // Checked state
      '&.Mui-checked': {
        color: 'blue', // Replace with your preferred color when checked for the thumb
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: 'grey', // Replace with your preferred color when checked for the track
      },
    },
  }));
  

interface Props {
    targetServer?: IngestionServer ;
    openState: boolean;
    setOpenState: (show:boolean) => void;
    dialogMode: string;
}


export const IngestionServerDialog = ({targetServer, openState, setOpenState, dialogMode } : Props) => {
    //console.log ("IngestionServerDialog: targetServer = ", targetServer)

    if (dialogMode === "edit") {
        console.log (dialogMode)
        console.log (targetServer)
    }

    const { t } = useTranslation();
    const [cookies, ,] = useCookies(["access_token"]);
    const [showSaveButton, setShowSaveButton] = useState (false);
    const [confirmDelete, setConfirmDelete] = useState (false);

    const [serverName, setServerName] = useState <string> (targetServer ? targetServer.name : "")
    const [serverNameChanged, setServerNameChanged] = useState (false);

    const [serverId, setServerId] = useState <string> (targetServer ? targetServer.server_id : "")
    const [serverIdChanged, setServerIdChanged] = useState (false);

    const [ingestionServer, setIngestionServer]               = useState <string> (targetServer ? targetServer?.server : "")
    const [ingestionServerChanged, setIngestionServerChanged] = useState (false);

    const [ingestionKey, setIngestionKey]               = useState <string> (targetServer ? targetServer?.key : "")
    const [ingestionKeyChanged, setIngestionKeyChanged] = useState (false);

    const [pemFileName, setPemFileName]       = useState <string>(targetServer ? targetServer?.pemFileName : '')
    const [pemFileChanged, setPemFileChanged] = useState (false);
    const [pemFileData, setPemFileData]       = useState <string> (targetServer ? targetServer?.pemFileData : '');
    
    const [saslUserName, setSaslUserName] = useState <string> (targetServer ? targetServer.sasl_username : "")
    const [saslUserNameChanged, setSaslUserNameChanged] = useState (false);
    const [saslPassword, setSaslPassword] = useState <string> (targetServer ? targetServer.sasl_password : "")
    const [saslPasswordChanged, setSaslPasswordChanged] = useState (false);

    //const [enabled, setenabled] = useState <boolean> (targetServer ? targetServer.enabled : false)
    //const [enabledChanged, setEnabledhanged] = useState (false);

    const [serverType, setServerType] = useState <string> (targetServer ? targetServer.serverType : NATIVE_KAFKA)
    const [serverTypeChanged, setServerTypeChanged] = useState (false);

    const theme = useTheme();

    const [isKafka, setIsKafka] = useState(targetServer?.serverType !== AZURE_EVENT_HUB); // true for Kafka, false for EventHub

    const handleClose = () => {
        setOpenState (false);
        window.location.href="/sysadmin"
    }

    const handleSaveChanges = () => {
        console.log ("Handle save changes:", dialogMode)

        if (dialogMode === "add") {
            const data = {
                "name"        : serverName,
                "server_id"   : serverId,
                "server"      : ingestionServer,
                "key"         : ingestionKey,
                "pemFileName" : pemFileName,
                "pemFileData" : pemFileData,
                "sasl_username" : saslUserName,
                "sasl_password" : saslPassword,
                "enabled"     : true,
                "serverType"  : serverType
            }
    
            // Create a new Ingestion Server
            IngestionServerService.addIngestionServer (data, cookies.access_token)
            .then((response) => {
                console.log("Success:", response);
                setShowSaveButton (false);
                setServerNameChanged (false);
                setOpenState (false);
                window.location.href = "/sysadmin";
            })
            .catch((errorResponse) => {
                console.log("Error:", errorResponse);
                console.log("HTTP error code:", errorResponse.status);
                if (errorResponse.status === 409) {
                    alert ("An ingestion server with id " + serverId + " already exists")
                }
            });

            return;
        } // if "add"


        // We have an EDIT
        if (!targetServer) {
            return;
        }

        const data = {
            "name"       : serverName,
            "server_id"  : serverId,
            "server"     : ingestionServer,
            "key"        : ingestionKey,
            "pemFileName"    : pemFileName,
            "enabled"    : true,
            "serverType" : serverType
        }

        console.log ("EDIT:", data)
        IngestionServerService.editIngestionServer (data, cookies.access_token)
        .then((res: any) => {
            //console.log("result:", res);
            targetServer.name = serverName;
            setShowSaveButton (false);
            setServerNameChanged (false);
            window.location.href = "/sysadmin";

        })
        .catch((err: any) => {
            console.log(err);
        });
    } // handleSaveChanges

    useEffect (() => {
        var showSave = false;
        switch (dialogMode) {
            case "edit" : {
                showSave= false; // Don't allow modifications!
                break;
            }

            case "add" : {
                var itemsChanged = false;
                if (isKafka) {
                    itemsChanged = pemFileChanged && saslUserNameChanged && saslPasswordChanged;
                } else {
                    itemsChanged = ingestionKeyChanged;
                }
                showSave = serverName !== '' && serverId !== '' && ingestionServer !== '' && serverType !== '' && itemsChanged;
                break;
            }
        }
        setShowSaveButton (showSave)

    }, [dialogMode, isKafka, serverName, serverId, ingestionServer, ingestionKey, serverType, pemFileChanged, 
                    saslUserNameChanged, saslPasswordChanged,
                    serverNameChanged, serverIdChanged, ingestionServerChanged, ingestionKeyChanged, serverTypeChanged])



    const deleteServer = () => {
        setConfirmDelete (true)
    };

    const cancelDelete = () => {
        setConfirmDelete (false)
    };

    const yesDelete = () => {
        // Really delete the VICU. Send a request to the back-end
        
        if (!targetServer) {
            return;
        }

        const data = {
            "server_id" : targetServer.server_id
        }
        IngestionServerService.deleteIngestionServer (data, cookies.access_token)
        .then((res: any) => {
            setConfirmDelete (false)
            window.location.href = "/sysadmin";
        })
        .catch((err: any) => {
            console.log(err);
        });

    };
    
    const handleItemChange = (id: string, data: any) => {
        // eslint-disable-next-line
        const urlPattern = /^((https?:)?(\/\/)?([\da-z.-]+)?(\.[a-z.]{2,6})?([\/\w.-]*)*\/?)?$/;
        // eslint-disable-next-line
        const addressPattern = /^(https?:)?(\/\/)?([\da-z.-]+)?(\.[a-z.]{2,6})?(:\d{0,5})?([\/\w.-]*)*\/?$/;

        const stringPattern = /^[a-zA-Z0-9_-]*$/;
        switch (id) {

            case "serverId" : {
                const value: string = data.trim();
                if (stringPattern.test(value) || value === '') {
                  setServerId(value);
                }
            
                setServerIdChanged (value !== targetServer?.server_id)
                break;
            }

            case "serverName" : {
                const val: string = data;
                setServerName (val);
                setServerNameChanged (val !== targetServer?.name)
                break;
            }

            case "serverType" : {
                const selected: boolean = data; // true == AZURE_EVENT_HUB
                console.log ("Switch value:", data)
                setIsKafka(!selected);
                const server_type:string = selected ? AZURE_EVENT_HUB : NATIVE_KAFKA ;
                console.log ("Server type change from ", targetServer?.serverType, "to ", server_type)

                setServerType (server_type);
                setServerTypeChanged (server_type !== targetServer?.serverType)
                break;
            }


            case "ingestionServer" : {
                const value: string = data.trim();
                if (addressPattern.test(value) || value === '') {
                    setIngestionServer(value);
                  }
                setIngestionServerChanged (value !== targetServer?.server)
                break;
            }

            case "ingestionKey" : {
                const val: string = data.trim();
                setIngestionKey(val);
                setIngestionKeyChanged (val !== targetServer?.key)
                break;
            }

            case "saslUserName" : {
                const val: string = data.trim();
                setSaslUserName(val);
                setSaslUserNameChanged (val !== targetServer?.sasl_username)
                break;
            }
            case "saslPassword" : {
                const val: string = data.trim();
                setSaslPassword(val);
                setSaslPasswordChanged (val !== targetServer?.sasl_password)
                break;
            }

        } // switch


    } // handleItemChange

    const showDeleteButton = (dialogMode !== "add")
    //console.log ("Org info:", orgInfo)
    let disableEdit = dialogMode === "edit";  // Don't allow EDIT of these properties. Refactor and clean up TODO

    const handleFileChange = (event:any) => {

        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e:any) => {
                setPemFileData(e.target.result);
                setPemFileName(file.name);
                setPemFileChanged (true)
                console.log ("File contents:", e.target.result)
            };
            reader.readAsText(file);
        }
    };

    const handleDownload = () => {
        // Create a Blob from the pemFileData
        const blob = new Blob([pemFileData], { type: 'text/plain' });
        
        // Create a link element
        const link = document.createElement('a');
        
        // Set link's href to point to the Blob
        link.href = URL.createObjectURL(blob);
        
        // Set the download attribute to the filename
        link.download = pemFileName;
        
        // Append link to the body
        document.body.appendChild(link);
        
        // Trigger click on the link
        link.click();
        
        // Remove link from the body
        document.body.removeChild(link);
    };

    return (
    <>
        <Dialog open={confirmDelete} >
            <DialogTitle> {t ("confirm_server_delete") } </DialogTitle>
            <DialogContent>
                <Typography>{t("delete_server_notification")}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="space-between"  alignItems="center" >
                    <Button variant="outlined" onClick={cancelDelete}> { t("cancel") }</Button>
                    <Button variant="contained" onClick={yesDelete} color="warning">  { t("yes_delete") }</Button>
                </Grid>
            </DialogActions>
        </Dialog>


        <Dialog  open = {openState} onClose={handleClose}  maxWidth="lg" disableEscapeKeyDown>
            <DialogTitle>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Box>{t("server_administration")}</Box>
                    </Grid>

                    <Grid item>
                        <TextField
                            label={t("server_id")}
                            id="server_id"
                            type=""
                            value={serverId}
                            onChange={(e) => handleItemChange("serverId", e.target.value)}
                            disabled = {targetServer !== undefined}
                            InputLabelProps={{ shrink: true }}   
                            placeholder = {t("enter_unique_id")}         
                            sx={{
                                input: { color: theme.palette.text.primary, 
                                        background: !serverIdChanged || !targetServer ? theme.palette.background.default : theme.palette.warning.light},                                    
                                label: { color: theme.palette.text.primary },
                            }}
                        />

                    </Grid>

                </Grid>
            </DialogTitle>


            <DialogContent>

            <Grid container item pt={3} mb={1}  spacing={2}>

                {/* First Row */}
                <Grid item xs={12}>
                    <Grid container direction="row" columnSpacing={1}>
                        <Grid item xs={8}>
                            <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={t("server_name")}
                            id="server_name"
                            value={serverName}
                            placeholder = {t("decriptive_name")}
                            disabled = {disableEdit}
                            onChange={(e) => handleItemChange("serverName", e.target.value)}
                            sx={{
                                input: { color: theme.palette.text.primary, 
                                         background: !serverNameChanged || !targetServer ? theme.palette.background.default : theme.palette.warning.light},                                    
                                label: { color: theme.palette.text.primary },
                            }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>{t("native_kafka")}</Typography>
                        </Grid>
                        <Grid item>
                            <CustomSwitch checked={!isKafka} onChange={(e) => handleItemChange("serverType", e.target.checked)} 
                            disabled = {disableEdit}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>{t("azure_eventhub")}</Typography>
                        </Grid>

                    </Grid>

                </Grid> {/* Row Container */}


                {/* Second Row */}
                
                

                {/* Fifth Row */}
                <Grid item xs={12}>

                    <Grid container direction="row" columnSpacing={1}>

                        <Grid item xs={12}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                label={t("ingestion_server")}
                                id="ingestion_server"
                                value={ingestionServer}
                                placeholder = {t("ingestion_server_address")} 
                                onChange={(e) => handleItemChange ("ingestionServer", e.target.value)}
                                disabled = {disableEdit}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !ingestionServerChanged || !targetServer ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                }}
                            />
                        </Grid>

                    </Grid> 
                </Grid> {/* Sixth Row Container */}

                {/* Sixth Row */}
                <Grid item xs={12}>

                { !isKafka &&  
                    <Grid container direction="row" columnSpacing={1}>

                        <Grid item xs={12}>
                            <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    multiline
                                    label={t("ingestion_key")}
                                    id="ingestion_key"
                                    value={ingestionKey}
                                    placeholder = {t("ingestion_secret_key")}
                                    onChange={(e) => handleItemChange ("ingestionKey", e.target.value)}
                                    disabled = {disableEdit}
                                    sx = {{  
                                        '& input, & textarea': { color: theme.palette.text.primary, 
                                                 background: !ingestionKeyChanged || !targetServer ? theme.palette.background.default : theme.palette.warning.light},
                                        label: { color: theme.palette.text.primary },
                                    }}
                                />
                        </Grid>
                    </Grid> 
                }
                { isKafka && 
                    <Grid container direction="row" columnSpacing={1}>
                        
                        { dialogMode === "add" && <Grid container direction="row" columnSpacing={1}>
                            <Grid item xs={4}>
                                <Typography>Certificate File</Typography>
                                <div>
                                    <input
                                        accept=".pem" // You can change this to accept other file types
                                        id="pemfile_upload"
                                        type="file"
                                        onChange={handleFileChange}
                                    />
                                </div>
                                </Grid>
                            
                                <Grid item xs={3}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        label={t("sasl_username")}
                                        value={saslUserName}
                                        placeholder = {t("sasl_user_name")}
                                        onChange={(e) => handleItemChange ("saslUserName", e.target.value)}
                                        disabled = {disableEdit}
                                        sx = {{
                                            '& input, & textarea': { color: theme.palette.text.primary, 
                                                    background: !saslUserNameChanged || !targetServer ? theme.palette.background.default : theme.palette.warning.light},
                                            label: { color: theme.palette.text.primary },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        type="password"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        label={t("sasl_password")}
                                        value={saslPassword}
                                        placeholder = {t("sasl_password")}
                                        onChange={(e) => handleItemChange ("saslPassword", e.target.value)}
                                        disabled = {disableEdit}
                                        sx = {{  
                                            '& input, & textarea': { color: theme.palette.text.primary, 
                                                    background: !saslPasswordChanged || !targetServer ? theme.palette.background.default : theme.palette.warning.light},
                                            label: { color: theme.palette.text.primary },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        }


                        { dialogMode === "edit" && <Grid item xs={6}>
                                <Button variant="contained" onClick={handleDownload}>
                                    Download PEM file
                                </Button>
                            </Grid>
                        }
                    </Grid>
                    
                }
                </Grid> {/* Seventh Row Container */}

            </Grid> {/* Outer container */}

                
            


            </DialogContent>

            <DialogActions>
                <Grid container direction="row" justifyContent="space-between"  alignItems="center" >
                    <Button variant="outlined" onClick={handleClose}> { t("close") }</Button>
                    {showSaveButton && (
								<Button onClick={handleSaveChanges} variant="outlined"
                                        sx={{background: theme.palette.warning.light}}
                                >
									{t("save_changes")}
								</Button>
							)}

                    {showDeleteButton && <Button variant="outlined" onClick={deleteServer} >  { t("delete_server") }</Button>}
                </Grid>
            </DialogActions>

        </Dialog>

    </>
    )
}

interface ItemProps {
    title: any;
    content: any;
}

export const CardItem = ({ title, content} : ItemProps) => {
    return <Grid container> 
                <Grid item xs={6}> <Typography  >{ title } </Typography> </Grid>
                <Grid item xs={6}> <Typography  >{ content}</Typography> </Grid>
            </Grid>
}


/*



            case "userName" : {
                const val: string = data;
                setUserName (val);
                setUserNameChanged (val !== targetServer?.userName)
                break;
            }

            case "userPwd" : {
                const val: string = data;
                setUserPwd (val);
                setUserPwdChanged ((val !== "") && (val !== targetServer?.userPwd))
                break;
            }



<Grid item xs={6}>
                            <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    label={t("user_name")}
                                    id="user_name"
                                    value={userName}
                                    placeholder = {t("user_name")}
                                    onChange={(e) => handleItemChange ("userName", e.target.value)}
                                    disabled = {disableEdit}
                                    sx = {{  
                                        '& input, & textarea': { color: theme.palette.text.primary, 
                                                 background: !userNameChanged || !targetServer ? theme.palette.background.default : theme.palette.warning.light},
                                        label: { color: theme.palette.text.primary },
                                    }}
                                />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    label={t("user_pwd")}
                                    type="password"
                                    id="user_pwd"
                                    value={userPwd}
                                    placeholder = {t("password")}
                                    onChange={(e) => handleItemChange ("userPwd", e.target.value)}
                                    disabled = {disableEdit}
                                    sx = {{  
                                        '& input, & textarea': { color: theme.palette.text.primary, 
                                                 background: !userPdwChanged || !targetServer ? theme.palette.background.default : theme.palette.warning.light},
                                        label: { color: theme.palette.text.primary },
                                    }}
                                />
                        </Grid>

*/